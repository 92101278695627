import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import './App.css'
import Home from './src/components/home/Home';
import EnquiryPage from './src/components/enquiry/EnquiryPage';
import Footer from './src/components/footer/Footer';
import Pricing from './src/components/Pricing/Pricing';

function App() {
  return (
    <div className="App bg-black">
      <Router>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path='/enquiry' element={<EnquiryPage/>}/>
          <Route path='/pricing' element={<Pricing/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
