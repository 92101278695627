import React, { useEffect, useState } from "react";
import logo from "../../static/images/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { RiMenu3Fill } from "react-icons/ri";
import "../CustomMousePointer/style.css";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";

const navigationItems = [
  "Home",
  "HRMS",
  "CRM",
  "Task Management",
  "Accounts",
  "Contact",
  "Pricing",
];

function Navbar({ isScrolledToEnquiry }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [state, setState] = React.useState({
    right: false,
  });

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 2) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const links = [
    { name: "Home", href: "/" },
    { name: "HRMS", href: "/#features", ScrollLink },
    { name: "CRM", href: "/#features", ScrollLink },
    { name: "Task Management", href: "/#features", ScrollLink },
    { name: "Accounts", href: "/#features", ScrollLink },
    { name: "Contact", href: "/#contact", ScrollLink },
    { name: "Pricing", href: "/pricing" },
  ];

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleNavigation = (path) => {
    if (path === "close") {
      toggleDrawer("right", false)();
    } else {
      navigate(path);
      toggleDrawer("right", false)();
    }
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem disablePadding>
          <a
            href="/"
            className="flex w-full items-center justify-center pt-5 pb-10 "
          >
            <img src={logo} alt="Logo" className="h-4" />
            <span className="ps-1 font-medium text-2xl mac:text-3xl custom-font">
              REBS
            </span>
          </a>
        </ListItem>
        <div className="ps-5">
          {navigationItems.map((text) => (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() =>
                  handleNavigation(
                    ["HRMS", "CRM", "Task Management", "Accounts"].includes(text)
                      ? "/#features"
                      : text === "Close"
                      ? "close"
                      : text === "Home"
                      ? "/"
                      : `/${text.toLowerCase()}`
                  )
                }
                className="text-xl"
              >
                <ListItemText
                  primary={text}
                  primaryTypographyProps={{ style: { fontSize: "0.875rem" } }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </div>
      </List>
      <Box sx={{ flexGrow: 1 }} />
      <a
        href="/"
        className="flex justify-center items-center p-4 text-[10px] text-center"
      >
        Powered by Teqbae innovations & solutions (India) pvt ltd
      </a>
    </Box>
  );

  return (
    <div
      className={`w-full container mx-auto fixed top-0 z-[100] border-b-[0.5px] border-neutral-600 ${
        isScrolled ? "bg-black text-white" : "bg-transparent text-black"
      }`}
    >
      <div className="h-14 lg:h-20 w-full ps-5 md:px-10 flex justify-between items-center ">
        <Link to="/" className="flex items-center">
          <img src={logo} className="h-4" alt="Logo" />
          <p className="ps-1 font-medium text-2xl mac:text-3xl custom-font text-stone-50">
            REBS
          </p>
        </Link>
        <div className="hidden lg:flex ms-[8%] flex justify-between items-center text-gray-600 px-2 mt-1 py-2 border-2 border-neutral-600 rounded-full">
          <div className="flex items-center justify-between flex-grow">
            <div className="flex justify-center items-center text-white font-bold rounded-full transition duration-300">
              {links.map((link, index) =>
                link.href.startsWith("/") ? (
                  // Use React Router for external pages
                  <Link
                    key={index}
                    to={link.href}
                    className={`font-semibold mx-1 py-2 px-6 text-sm rounded-full transition duration-300 ${
                      location.pathname === link.href
                        ? "bg-white text-black"
                        : "bg-transparent text-white hover:text-black hover:bg-white"
                    }`}
                  >
                    {link.name}
                  </Link>
                ) : (
                  // Use react-scroll for internal section links
                  <ScrollLink
                    key={index}
                    to={link.href}
                    smooth={true}
                    duration={500}
                    offset={-70} // Adjust based on navbar height
                    className="cursor-pointer font-semibold mx-1 py-2 px-6 text-sm rounded-full transition duration-300 hover:text-black hover:bg-white"
                  >
                    {link.name}
                  </ScrollLink>
                )
              )}
            </div>
          </div>
        </div>
        <div className="hidden lg:flex">
          <div className="flex gap-x-10 items-center">
            <Link
              to="https://platform.rebs.in/"
              className="font-semibold text-white"
            >
              Login
            </Link>
            <div className="border-2 font-semibold border-neutral-600 rounded-3xl py-2 px-6">
              <p className="text-white">Get Started</p>
            </div>
          </div>
        </div>
        <div className="lg:hidden flex items-center">
          <Button onClick={toggleDrawer("right", true)}>
            <RiMenu3Fill className="text-white" size={22} />
          </Button>
          <SwipeableDrawer
            anchor="right"
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
            onOpen={toggleDrawer("right", true)}
          >
            {list("right")}
          </SwipeableDrawer>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
