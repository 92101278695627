import React from "react";
import logo from "../../static/images/logo.png";
import playStore from "../../static/images/android-4.webp";
import appStore from "../../static/images/apple-1.webp";

function Footer() {
  return (
    <div className="border-t border-neutral-600 text-white w-full">
      <div className="w-full px-6 lg:px-10 xl:px-48 grid grid-cols-2 lg:grid-cols-4 gap-x-24 py-10">
        <div className="">
          <div className="flex items-center">
            <img src={logo} className="h-4" />
            <p className="ps-1 font-medium text-2xl mac:text-3xl custom-font">REBS</p>
          </div>
          <p className="text-sm pt-5">
            Effortlessly Automate and Enhance Your Business Operations with REBS
            Solutions
          </p>
        </div>
        <div className="">
          <div>
            <p className="font-semibold">Location</p>
            <p className="pt-5 text-sm">
              Cloud 9 Building, 9th Cross Rd, above Starbucks, MIG Housing
              Society, Panampilly Nagar, Ernakulam, Kerala 682036
            </p>
          </div>
          <div className="pt-5 text-sm">
            <p className="text-sm border-b-2 pb-1">+91 9947844484</p>
            <p className="pt-1">ebox@teqbae.com</p>
          </div>
        </div>
        <div className="pt-10 lg:pt-0">
          <p className="font-semibold">Sections</p>
          <p className="pt-5 text-sm">Features</p>
          <p className="pt-1 text-sm">About</p>
          <p className="pt-1 text-sm">Home</p>
        </div>
        <div className="pt-10 lg:pt-0">
          <p className="font-semibold">Company</p>
          <p className="pt-5 text-sm">About Us</p>
          <p className="pt-1 text-sm">Contact Us</p>
          <div className="flex flex-wrap justify-center w-full gap-x-5 gap-y-5 md:flex-col pt-5">
            <a
              href="https://play.google.com/store/apps/details?id=com.teqbae.rebs&hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={playStore} className="h-8" />
            </a>
            <a
              href="https://apps.apple.com/in/app/rebs-hr/id6738992236"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={appStore} className="h-8" />
            </a>
          </div>
        </div>
      </div>
      <div className="border-t border-neutral-600 text-sm w-full flex justify-center">
        <p className="py-5 text-center">
          © 2025, <span className="text-green-400">Teqbae</span> Innovations and Solutions India Pvt Ltd. All Rights
          Reserved.
        </p>
      </div>
    </div>
  );
}

export default Footer;
