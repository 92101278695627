import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import robo from "../../static/images/99e33d7e-1792-42cc-8f27-1acabc497678.jpeg";
import playStore from "../../static/images/android-4.webp";
import appStore from "../../static/images/apple-1.webp";

const features = {
  HRMS: [
    "Automated Attendance Tracking",
    "Customizable Payroll Management",
    "Leave and Holiday Scheduling",
    "Performance Appraisal Tools",
    "Employee Self-Service Portal",
    "Mobile App",
  ],
  CRM: [
    "Lead & Contact Management",
    "Sales Pipeline Task & Activity Tracking",
    "Email Integration Reporting & Analytics",
    "Campaign Management",
    "Mobile Access",
  ],
  TaskManagement: [
    "Task Assignment",
    "Progress Tracking",
    "Reminders & Notifications",
    "Priority Management",
    "Collaboration Tools",
    "Kanban Boards",
    "Reporting & Analytics",
  ],
  Accounts: [
    "Payment Management",
    "Invoice & Expense Tracking",
    "Automated Reports Tax & Compliance",
    "Multi-Currency Support",
    "Audit Logs",
  ],
};

function Features() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <div className="p-5 md:p-10 border border-neutral-600 rounded-3xl w-full">
      <p className="text-red-500 font-bold text-lg 4xl:text-xl">REBS</p>
      <div className="w-full hd1366:flex gap-x-5">
        <div className="w-full hd1366:w-1/2">
          <div>
            <p className="text-white text-4xl pt-5 leading-tight">
              Business Automation <br className="" /> Solution
            </p>
          </div>
          <div className="text-xs hd1366:text-sm 4xl:text-base normal-font">
            <p className="normal-font Word-Spacing pt-8 text-white">
              A Business Automation Solution streamlines processes and tasks,
              improving efficiency and productivity by reducing manual effort
              and errors.
            </p>
            <p className="normal-font Word-Spacing pt-5 text-white">
              REBS provides a comprehensive set of features to enhance lead
              generation, accelerate sales, and evaluate sales performance.
            </p>
          </div>
          <div className="w-full md:flex md:gap-x-5 mt-10" ref={ref}>
            <motion.div
              className="bg-violet-300 w-full md:w-1/2 rounded-3xl px-5 py-7 4xl:px-7 4xl:py-10"
              initial={{ x: "-25vw", opacity: 0 }}
              animate={inView ? { x: 0, opacity: 1 } : {}}
              transition={{
                duration: 2,
                ease: "easeInOut",
              }}
            >
              <div className="w-full flex items-center justify-between">
                <h2 className="text-xl 2xl:text-2xl font-medium text-black ">
                  HRMS
                </h2>
                <div className="bg-black h-8 w-8 flex justify-center items-center rounded-full ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="18px"
                    viewBox="0 -960 960 960"
                    width="18px"
                    fill="#FFFFFF"
                  >
                    <path d="m216-160-56-56 464-464H360v-80h400v400h-80v-264L216-160Z" />
                  </svg>
                </div>
              </div>
              <ul className="text-black list-disc pl-5 mt-4">
                {features.HRMS.map((feature, index) => (
                  <li
                    key={index}
                    className="text-sm 4xl:text-lg normal-font hd1366:leading-5 3xl:leading-7 4xl:leading-10 font-normal"
                  >
                    {feature}
                  </li>
                ))}
              </ul>
            </motion.div>
            <motion.div
              className="bg-green-200 w-full mt-5 md:mt-0 md:w-1/2 rounded-3xl px-5 py-7 4xl:px-7 4xl:py-10"
              initial={{ x: "-25vw", opacity: 0 }}
              animate={inView ? { x: 0, opacity: 1 } : {}}
              transition={{
                duration: 2, // Duration of the animation
                ease: "easeInOut", // Ease-in and ease-out effect
                delay: 2,
              }}
            >
              <div className="w-full flex items-center justify-between">
                <h2 className="text-xl 2xl:text-2xl font-medium text-black">
                  CRM
                </h2>
                <div className="bg-black h-8 w-8 flex justify-center items-center rounded-full ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="18px"
                    viewBox="0 -960 960 960"
                    width="18px"
                    fill="#FFFFFF"
                  >
                    <path d="m216-160-56-56 464-464H360v-80h400v400h-80v-264L216-160Z" />
                  </svg>
                </div>
              </div>
              <ul className="text-black list-disc pl-5 mt-4">
                {features.CRM.map((feature, index) => (
                  <li
                    key={index}
                    className="text-sm 4xl:text-lg normal-font hd1366:leading-5 3xl:leading-7 4xl:leading-10 font-normal"
                  >
                    {feature}
                  </li>
                ))}
              </ul>
            </motion.div>
          </div>
        </div>
        <div className="w-full mt-5 hd1366:mt-0 hd1366:w-1/2">
          <motion.div
            className="rounded-3xl w-full bg-cover bg-center h-96 xl:h-full"
            style={{ backgroundImage: `url(${robo})` }}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 2.5, duration: 1, ease: "easeOut" }}
          />
        </div>
      </div>
      <div className="w-full md:flex gap-x-5 mt-10">
        <div className="hidden hd1366:block w-1/4 items-center border border-neutral-600 rounded-3xl">
          <div className="bg-transparent w-full h-full flex flex-col justify-center items-center gap-y-5 p-5">
            <a
              href="https://play.google.com/store/apps/details?id=com.teqbae.rebs&hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={playStore} className="h-10 4xl:h-14" alt="Play Store" />
            </a>
            <a
              href="https://apps.apple.com/in/app/rebs-hr/id6738992236"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={appStore} className="h-10 4xl:h-14" />
            </a>
          </div>
        </div>
        <motion.div
          initial={{ x: "25vw", opacity: 0 }}
          animate={inView ? { x: 0, opacity: 1 } : {}}
          transition={{
            duration: 2,
            ease: "easeInOut",
          }}
          className="bg-amber-100 w-full md:w-1/3 hd1366:w-1/4 rounded-3xl p-5 py-7 4xl:px-7 4xl:py-10"
        >
          <div className="w-full flex items-center justify-between">
            <h2 className="text-xl 2xl:text-2xl font-medium text-black">
              Task Management
            </h2>
            <div className="bg-black h-8 w-8 flex justify-center items-center rounded-full ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="18px"
                viewBox="0 -960 960 960"
                width="18px"
                fill="#FFFFFF"
              >
                <path d="m216-160-56-56 464-464H360v-80h400v400h-80v-264L216-160Z" />
              </svg>
            </div>
          </div>
          <ul className="text-black list-disc pl-5 mt-4">
            {features.TaskManagement.map((feature, index) => (
              <li
                key={index}
                className="text-sm 4xl:text-lg normal-font hd1366:leading-5 3xl:leading-7 4xl:leading-10 font-normal"
              >
                {feature}
              </li>
            ))}
          </ul>
        </motion.div>
        <motion.div
          initial={{ x: "20vw", opacity: 0 }}
          animate={inView ? { x: 0, opacity: 1 } : {}}
          transition={{
            duration: 2, // Duration of the animation
            ease: "easeInOut", // Ease-in and ease-out effect
            delay: 2,
          }}
          className="bg-pink-300 w-full mt-5 md:mt-0 md:w-1/3 hd1366:w-1/4 rounded-3xl p-5 py-7 4xl:px-7 4xl:py-10"
        >
          <div className="w-full flex items-center justify-between">
            <h2 className="text-xl 2xl:text-2xl font-medium text-black">
              Accounts
            </h2>
            <div className="bg-black h-8 w-8 flex justify-center items-center rounded-full ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="18px"
                viewBox="0 -960 960 960"
                width="18px"
                fill="#FFFFFF"
              >
                <path d="m216-160-56-56 464-464H360v-80h400v400h-80v-264L216-160Z" />
              </svg>
            </div>
          </div>
          <ul className="text-black list-disc pl-5 mt-4">
            {features.Accounts.map((feature, index) => (
              <li
                key={index}
                className="text-sm 4xl:text-lg hd1366:leading-5 3xl:leading-7 4xl:leading-10 normal-font font-normal"
              >
                {feature}
              </li>
            ))}
          </ul>
        </motion.div>
        <div className="bg-transparent gap-y-5 hd1366:gap-y-0 hd1366:w-1/4 flex flex-col justify-center items-center p-5">
          <div className="gradient-border-2">
            <button className="gradient-button flex gap-x-1">
              Explore More
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#FFFFFF"
              >
                <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z" />
              </svg>
            </button>
          </div>
          <div className="flex flex-wrap justify-center w-full gap-x-5 gap-y-5 md:flex-col">
            <a
              href="https://play.google.com/store/apps/details?id=com.teqbae.rebs&hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={playStore} className="h-10 hd1366:hidden" />
            </a>
            <a
              href="https://apps.apple.com/in/app/rebs-hr/id6738992236"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={appStore} className="h-10 hd1366:hidden" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
