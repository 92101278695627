import React, { useState, useEffect, useRef } from "react";
import EnquiryPage from "../enquiry/EnquiryPage";
import "../../static/styles/styles.css";
import shine from "../../static/images/Rebs Title Vector.svg";
import pinkDesign from "../../static/images/Line vector.svg";
import Navbar from "../navbar/Navbar";
import adanim from "../../static/gif/Comp1_.gif";
import "../Cursor/cursor.css";

function LandingPage() {
  const canvasRef = useRef(null);
  const [hoveredParticleIndex, setHoveredParticleIndex] = useState(null);

  useEffect(() => {
  const canvas = canvasRef.current;
  const ctx = canvas.getContext("2d");

  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;

  let mouse = {
    x: null,
    y: null,
  };

  window.addEventListener("mousemove", (event) => {
    mouse.x = event.clientX;
    mouse.y = event.clientY;
  });

  class Particle {
    constructor(x, y, speedX, speedY) {
      this.x = x;
      this.y = y;
      this.speedX = speedX * 0.2;
      this.speedY = speedY * 0.2;
      this.lifetime = Math.random() * 900 + 900; // Random lifetime (200-300 frames)
      this.age = 0;
    }

    update() {
      const dx = mouse.x - this.x;
      const dy = mouse.y - this.y;
      const distance = Math.sqrt(dx * dx + dy * dy);

      if (distance < 100) {
        this.x += dx * 0.02; // Adjust the multiplier to control speed
        this.y += dy * 0.02;
      } else {
        this.x += this.speedX;
        this.y += this.speedY;
      }

      if (this.x > canvas.width || this.x < 0) {
        this.speedX = -this.speedX;
      }
      if (this.y > canvas.height || this.y < 0) {
        this.speedY = -this.speedY;
      }

      this.age++; // Increment age
    }

    draw() {
      ctx.beginPath();
      ctx.arc(this.x, this.y, 2, 0, Math.PI * 2);
    
      const progress = this.age / this.lifetime; // Normalize age to 0-1
    
      // Define key colors in RGB
      const colors = [
        { r: 8, g: 148, b: 255 },  // Blue (#0894ff)
        { r: 201, g: 89, b: 221 }, // Violet (#c959dd)
        { r: 255, g: 46, b: 84 },  // Rose (#ff2e54)
        { r: 255, g: 144, b: 4 }   // Orange (#ff9004)
      ];
    
      // Calculate which segment the progress falls into
      const numStages = colors.length - 1; // Number of color transitions
      const stageProgress = progress * numStages;
      const currentStage = Math.floor(stageProgress);
      const stageFraction = stageProgress - currentStage; // Progress within the current stage
    
      // Interpolate between the current stage and the next stage
      const startColor = colors[currentStage];
      const endColor = colors[Math.min(currentStage + 1, numStages)];
      
      const r = Math.floor(startColor.r + (endColor.r - startColor.r) * stageFraction);
      const g = Math.floor(startColor.g + (endColor.g - startColor.g) * stageFraction);
      const b = Math.floor(startColor.b + (endColor.b - startColor.b) * stageFraction);
    
      ctx.fillStyle = `rgb(${r}, ${g}, ${b})`;
      ctx.fill();
    }
    

    isDead() {
      return this.age >= this.lifetime; // Check if particle's lifetime is over
    }
  }

  const particles = [];
  const numParticles = 25;

  // Initialize particles
  for (let i = 0; i < numParticles; i++) {
    const x = Math.random() * canvas.width;
    const y = Math.random() * canvas.height;
    const speedX = (Math.random() - 0.5) * 0.2;
    const speedY = (Math.random() - 0.5) * 1.2;
    particles.push(new Particle(x, y, speedX, speedY));
  }

  function animate() {
    requestAnimationFrame(animate);
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    for (let i = particles.length - 1; i >= 0; i--) {
      const particle = particles[i];
      if (particle.isDead()) {
        particles.splice(i, 1); // Remove dead particle

        // Create a new particle at a random position
        const x = Math.random() * canvas.width;
        const y = Math.random() * canvas.height;
        const speedX = (Math.random() - 0.5) * 0.2;
        const speedY = (Math.random() - 0.5) * 1.2;
        particles.push(new Particle(x, y, speedX, speedY));
      } else {
        particle.update();
        particle.draw();
      }
    }
  }

  animate();

  return () => {
    window.removeEventListener("mousemove", null);
  };
}, []);


  const handleMouseMove = (event) => {
    const { clientX, currentTarget } = event;
    const rect = currentTarget.getBoundingClientRect();
    const mouseX = clientX - rect.left;
    const containerWidth = rect.width;

    const normalizedX = (mouseX / containerWidth) * 2 - 1;

  };

  return (
    <div  onMouseMove={handleMouseMove} className="h-full w-full flex flex-col justify-around md:items-center bg-black lg:rounded-b-[100px] overflow-hidden" >
      <canvas
        id="canvas"
        ref={canvasRef}
        className="absolute lg:flex z-10 w-full h-full bg-black lg:rounded-b-[100px]"
      ></canvas>
      <div className="md:items-center px-5 md:px-0 z-10 cursor-default">
        <div className="flex flex-col md:justify-center items-start md:items-center">
          <div className="flex w-full justify-center md:hidden -mt-3 md:px-5 lg:px-8 sc369:text-2xl sc396:text-3xl sc420:text-4xl text-3xl md:text-3xl xl:text-4xl 2xl:text-5xl 4xl:text-6xl mac:text-6xl">
            <p className="text-white w-full text-center font-bold">
              Automate Your <br className="md:hidden" />
              Business With&nbsp;
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#0894ff] via-[#c959dd] via-[#ff2e54] to-[#ff9004]">
                {" "}
                REBS{" "}
              </span>
            </p>
          </div>
          <p className="text-white hidden md:block w-full text-center -mt-3 md:px-5 lg:px-8 text-2xl md:text-3xl xl:text-4xl 2xl:text-5xl 3xl:text-[56px] mac:text-[56px] font-extrabold tracking-wide	">
            Automate Your Business 
          </p> 
          <p className="text-white hidden md:block w-full text-left md:text-center md:px-5 lg:px-8 text-2xl md:text-3xl xl:text-4xl 2xl:text-5xl 3xl:text-[56px] mac:text-[56px] font-extrabold pt-3 tracking-wide	"> Operations With 
            <span className="text-transparent font-semibold tracking-wide bg-clip-text bg-gradient-to-r from-[#0894ff] via-[#c959dd] via-[#ff2e54] to-[#ff9004]">
              &nbsp;REBS
            </span>
            </p>
          <div className="flex w-full flex-col justify-center items-center md:px-0">
            <p className="text-neutral-400 text-center pt-7 text-xs md:text-sm lg:text-base leading-loose">
              Connect with us to explore software solutions{" "}
              <br className="md:hidden" /> that automate tasks, boost
              efficiency,
              <br className="hidden md:block" /> and{" "}
              <br className="md:hidden" /> free up your team for strategic
              growth.
            </p>
          </div>
        </div>
      </div>
      {/* <div className="sc369:h-56 sc396:h-56 h-80 lg:h-80 xl:h-96 z-50">
        <img
          src={adanim}
          className="h-full w-full object-cover"
          alt="Description of GIF"
        />
      </div> */}
    </div>
  );
}

export default LandingPage;
