import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";

import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";

const features = [
  {
    title: "Enhanced Efficiency",
    description: "Automate tasks and streamline operations.",
  },
  {
    title: "Security & Reliability",
    description: "Protect your data with advanced security features.",
  },
  {
    title: "Cost-Effective",
    description: "Reduce operational costs with optimized solutions.",
  },
];

const plans = [
  {
    title: "HRMS",
    price: "₹ 2,500",
    details: "(Min. 10 user / 250*10)",
  },
  {
    title: "CRM",
    price: "₹ 3,500",
    details: "(Min. 10 user / 350*10)",
  },
  {
    title: "Mobile Application",
    price: "₹ 1 Lakh",
    details: "(depending on complexity)",
  },
  {
    title: "Website",
    price: "₹ 60,000 &nbsp; ₹ 90,000",
    details: "(Basic) (Advanced)",
  },
  {
    title: "Accounting Software",
    price: "₹ 3,000",
    details: "(depending on complexity)",
  },
  {
    title: "Task Management",
    price: "₹ 3,500",
    details: "(Min. 10 user/ 350*10)",
  },
];

function Pricing() {

    const navigate = useNavigate();
    const handleSubscribeClick = () => {
        navigate("/#contact", { replace: true }); 
    };

    useEffect(() => {
          window.scrollTo(0, 0); 
        }, []);
    

  return (
    <div className="bg-black container mx-auto">
      <div className="">
        <div className="w-full fixed top-0 z-[60] bg-stone-100 text-black">
          <Navbar />
        </div>
        <div className=" w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-[30%,20%,20%,20%] mt-24 gap-y-10 xl:gap-y-0 gap-x-10 p-10">
          <div className="bg-transparent border border-neutral-600 rounded-lg flex flex-col justify-between px-10">
            <div className=" pt-10">
              <p className="text-white text-2xl font-semibold">Our Plans</p>
              <p className="text-neutral-600 text-xs lg:text-sm pt-8">
                Flexible, affordable plans tailored to your needs. From HRMS to
                CRM and custom solutions—grow efficiently with us.
              </p>
            </div>
            <div className="pb-10">
              {features.map((feature, index) => (
                <div key={index} className={"border-b border-neutral-600"}>
                  <p className="text-white pt-5">{feature.title}</p>
                  <p className="text-xs lg:text-sm text-neutral-400 py-3">
                    {feature.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="bg-neutral-800 border border-neutral-600 rounded-lg">
            <div className="flex flex-col items-center py-10">
              <p className="text-white text-xl">HRMS</p>
              <p className="text-white text-xl font-semibold pt-8">₹ 2,500</p>
              <p className="text-neutral-400 text-sm pt-2 pb-5">
                (Min. 10 user &nbsp;/ &nbsp;250*10)
              </p>
              <div className="gradient-border-2">
                <button onClick={handleSubscribeClick} className="gradient-button flex gap-x-1">
                  Subscribe
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#FFFFFF"
                  >
                    <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z" />
                  </svg>
                </button>
              </div>
            </div>
            <div className="flex flex-col items-center  py-10">
              <p className="text-white text-xl">CRM</p>
              <p className="text-white text-xl font-semibold pt-8">₹ 3,500</p>
              <p className="text-neutral-400 text-sm pt-2 pb-5">
                (Min. 10 user &nbsp;/ &nbsp;350*10)
              </p>
              <div className="gradient-border-2">
                <button onClick={handleSubscribeClick} className="gradient-button flex gap-x-1">
                  Subscribe
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#FFFFFF"
                  >
                    <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="bg-neutral-800 border border-neutral-600 rounded-lg">
            <div className="flex flex-col items-center  py-10">
              <p className="text-white text-xl">Mobile Application</p>
              <p className="text-white text-xl font-semibold pt-8">₹ 1 Lakh</p>
              <p className="text-neutral-400 text-sm pt-2 pb-5">
                (depending on complexity)
              </p>
              <div className="gradient-border-2">
                <button onClick={handleSubscribeClick} className="gradient-button flex gap-x-1">
                  Subscribe
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#FFFFFF"
                  >
                    <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z" />
                  </svg>
                </button>
              </div>
            </div>
            <div className="flex flex-col items-center  py-10">
              <p className="text-white text-xl">Website </p>
              <div className="w-full items-center flex justify-center gap-x-5">
                <div>
                  <p className="text-white text-xl font-semibold pt-8">
                    ₹ 60,000
                  </p>
                  <p className="text-neutral-400 text-sm pt-2 pb-5 text-center">
                    (Basic)
                  </p>
                </div>
                <div>
                  <p className="text-white text-xl font-semibold pt-8">
                    ₹ 90,000
                  </p>
                  <p className="text-neutral-400 text-sm pt-2 pb-5 text-center">
                    (Advanced)
                  </p>
                </div>
              </div>
              <div className="gradient-border-2">
                <button onClick={handleSubscribeClick} className="gradient-button flex gap-x-1">
                  Subscribe
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#FFFFFF"
                  >
                    <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="bg-neutral-800 border border-neutral-600 rounded-lg">
            <div className="flex flex-col items-center py-10">
              <p className="text-white text-xl">Accounting Software</p>
              <p className="text-white text-xl font-semibold pt-8">₹ 3,000</p>
              <p className="text-neutral-400 text-sm pt-2 pb-5">
                (depending on complexity)
              </p>
              <div className="gradient-border-2">
                <button onClick={handleSubscribeClick} className="gradient-button flex gap-x-1">
                  Subscribe
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#FFFFFF"
                  >
                    <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z" />
                  </svg>
                </button>
              </div>
            </div>
            <div className="flex flex-col items-center py-10">
              <p className="text-white text-xl">Task Management</p>
              <p className="text-white text-xl font-semibold pt-8">₹ 3,500</p>
              <p className="text-neutral-400 text-sm pt-2 pb-5">
                (Min. 10 user &nbsp;/ &nbsp;350*10)
              </p>
              <div className="gradient-border-2">
                <button onClick={handleSubscribeClick} className="gradient-button flex gap-x-1">
                  Subscribe
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#FFFFFF"
                  >
                    <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5 bg-black">
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Pricing;
