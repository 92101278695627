import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import LandingPage from "../landing/LandingPage";
import EnquiryPage from "../enquiry/EnquiryPage";
import Features from "../landing/Features";
import Footer from "../footer/Footer";

function Home() {
  const [showPointer, setShowPointer] = useState(true);
  const location = useLocation();

  // useEffect(() => {
  //   window.scrollTo(0, 0); 
  // }, []);

  useEffect(() => {
    if (location.hash) {
      const targetElement = document.getElementById(location.hash.substring(1));
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div className="bg-black container mx-auto">
      <div className="h-screen bg-black ">
        <div className="w-full fixed top-0 z-[60] bg-stone-100 text-black">
          <Navbar />
        </div>
        <div className="bg-black">
          <div
            //onMouseEnter={() => setShowPointer(true)}
            //onMouseLeave={() => setShowPointer(false)}
            className="h-[75vh] lg:h-[100vh] bg-black"
          >
            <LandingPage className='bg-black'/>
          </div>
          <div id='features' className="pt-20 px-5 md:px-20 xl:px-32 bg-black ">
            <Features/>
          </div>
          <div id='contact' className="pt-20 px-5 lg:px-20 xl:px-32 bg-black ">
            <EnquiryPage />
          </div>
          {/* {showPointer && (
            <div className="CustomMousePointer">
              <CustomMousePointer />
            </div>
          )} */}
        </div>
        <div className="pt-32 bg-black">
          <Footer/>
        </div>
      </div>
    </div>
  //   <div>
      
  //     <div>
  //       <LandingPageAnimation/>
  //     </div>
  //   </div>
  );
}

export default Home;
