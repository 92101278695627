import React from "react";
import EnquiryFrom from "./EnquiryFrom";
import doublearrow from "../../static/images/double-arrow.png";
import formimg from "../../static/images/form-side-img.jpg";
import "../../static/styles/styles.css";

function EnquiryPage() {
  return (
    <div className="flex flex-col lg:flex-row w-full bg-black justify-between ">
      <div className="w-full lg:w-[45%] h-full flex flex-col gap-y-16 pt-4">
        <div className="relative flex-1 items-center">
          <p className="text-neutral-800 tracking-widest font-extrabold text-5xl md:text-6xl lg:text-6xl 2xl:text-5xl">
            CONTACT
          </p>
          <p className="text-red-500 font-semibold text-sm lg:text-sm 2xl:text-base absolute top-1/3 left-5 xl:left-5">
            CONTACT
          </p>
        </div>
        <div>
          <p className="text-white text-3xl">Get in touch</p>
          <p className="text-neutral-500 pt-5 text-sm">
            Enter your details to begin your enquiry. Upon successful payment,
            you'll receive your login credentials to access our
            tailored solutions.
          </p>
        </div>
        <div className="flex flex-col gap-y-5 ">
          <div className="border rounded-lg flex border-neutral-600 px-3 py-6 gap-x-3">
            <div className="border rounded-lg flex justify-center items-center w-10 h-10">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20px"
                viewBox="0 -960 960 960"
                width="20px"
                className=" fill-gray-400"
              >
                <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z" />
              </svg>
            </div>
            <div className="">
              <p className="text-xs lg:text-sm text-white">Email</p>
              <p className="text-white text-sm mac:text-lg font-semibold">
                ebox@teqbae.com
              </p>
            </div>
          </div>
          <div className="border rounded-lg flex border-neutral-600 px-3 py-6 gap-x-3">
            <div className="border rounded-lg flex justify-center items-center w-10 h-10">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20px"
                viewBox="0 -960 960 960"
                width="20px"
                className="fill-gray-400"
              >
                <path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z" />
              </svg>
            </div>
            <div>
              <p className="text-xs lg:text-sm text-white">Phone</p>
              <p className="text-white text-sm mac:text-lg font-semibold">
                +91 9947404415
              </p>
            </div>
          </div>
          <div className="border rounded-lg flex border-neutral-600 px-3 py-6 gap-x-3">
            <div className="border rounded-lg flex justify-center items-center w-10 h-10">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20px"
                viewBox="0 -960 960 960"
                width="20px"
                className="fill-gray-400"
              >
                <path d="M440-280H280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h160v80H280q-50 0-85 35t-35 85q0 50 35 85t85 35h160v80ZM320-440v-80h320v80H320Zm200 160v-80h160q50 0 85-35t35-85q0-50-35-85t-85-35H520v-80h160q83 0 141.5 58.5T880-480q0 83-58.5 141.5T680-280H520Z" />
              </svg>
            </div>
            <div>
              <p className="text-xs lg:text-sm text-white">Website</p>
              <p className="text-white text-sm mac:text-lg font-semibold">
                www.teqbae.com
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full lg:w-[45%] mt-10 lg:mt-0">
        <EnquiryFrom />
      </div>
    </div>
  );
}

export default EnquiryPage;

// function EnquiryPage() {
//   return (
//     <div className="flex w-full bg-black">
//       <div className="w-full">
//         <div className="lg:w-[45%] xl:w-[45%] 2xl:w-[40%] 3xl:w-1/3">
//           <img src={doublearrow} className="h-12 mac:h-16" />
//           <p className="text-white text-xl lg:text-2xl mac:text-4xl font-bold">
//             Enquiry Today!
//           </p>
//           <p className="pt-5 text-xs lg:text-sm mac:text-base text-neutral-500">
//             Enter your details to begin your enquiry. Upon successful payment,
//             you'll receive your login credentials to access our tailored
//             solutions.
//           </p>
//         </div>
//         <div className="flex flex-col lg:flex-row w-full pb-10">
//           <div className="w-full lg:w-1/2 mt-10 pe-3 mac:pe-5">
//             <EnquiryFrom />
//           </div>
//           <div className="w-full lg:w-1/2 ps-3 mac:ps-5 mt-10 ">
//             <div className="w-full h-1/2 object-cover rounded-3xl overflow-hidden">
//               <img
//                 src={formimg}
//                 className="h-full w-full object-cover rounded-3xl"
//               />
//             </div>
//             <div className="w-full h-1/2 flex pt-5 ">
//               <div className="w-1/2 h-full me-3 mac:me-5 px-5 2xl:px-10 md:py-8 lg:py-0 flex flex-col justify-center rounded-3xl relative overflow-hidden ">
//                 <div className="glow-1"></div>
//                 <div className="glow-1 glow-2"></div>
//                 <div className="relative z-10 ">
//                   <p className="text-white pt-4 md:pt-2 text-sm lg:text-base 2xl:text-lg mac:text-2xl font-semibold">
//                     TEQBAE INNOVATIONS: Driving Business Success
//                   </p>
//                   <p className="py-5 2xl:py-7 mac:py-5 md:pe-10 xl:pe-10 mac:pe-16 text-xs mac:text-base text-neutral-500">
//                     We deliver innovative, reliable software solutions tailored
//                     to help businesses thrive with cutting-edge technology and
//                     exceptional service.
//                   </p>
//                 </div>
//               </div>
//               <div className="w-1/2 ms-3 mac:ms-5 px-4 md:px-8 xl:px-10 md:py-8 lg:py-0 flex flex-col justify-center rounded-3xl relative overflow-hidden">
//                 <div className="glow-1"></div>
//                 <div className="glow-1 glow-2"></div>
//   <div className="relative z-10 ">
//     <p className="text-xs mac:text-base text-neutral-500">
//       email
//     </p>
//     <p className="text-white text-sm xl:text-base mac:text-lg font-semibold">
//       ebox@teqbae.com
//     </p>
//   </div>
//   <div className="py-5 2xl:py-7">
//     <p className="text-xs mac:text-base text-neutral-500">
//       Phone
//     </p>
//     <p className="text-white text-sm xl:text-base mac:text-lg font-semibold">
//       +91 9947404415
//     </p>
//   </div>
//   <div className="">
//     <p className="text-xs mac:text-base text-neutral-500">
//       website
//     </p>
//     <p className="text-white text-sm xl:text-base mac:text-lg font-semibold">
//       www.teqbae.com
//     </p>
//   </div>
// </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default EnquiryPage;

{
  /* <div
                className="w-1/2 h-full me-3 mac:me-5 px-5 2xl:px-10 md:py-8 lg:py-0 flex flex-col justify-center rounded-3xl bg-neutral-900 relative"
                style={{
                  borderRadius:'1.5 rem',
                  overflow:'hidden',
                  boxShadow: `
          inset 0 10px 15px -5px rgba(59, 130, 246, 0.5),
          inset 10px 0 15px -5px rgba(249, 115, 22, 0.5),
          inset 0 -10px 15px -5px rgba(249, 115, 22, 0.5),
          inset -10px 0 15px -5px rgba(59, 130, 246, 0.5)
        `,
                }}
              >
                <div
                  className="absolute inset-0 pointer-events-none z-0"
                  style={{
                    background: `
            linear-gradient(to right, rgba(59, 130, 246, 0.3), rgba(236, 72, 153, 0.3)) no-repeat,
            linear-gradient(to bottom, rgba(236, 72, 153, 0.3), rgba(249, 115, 22, 0.3)) no-repeat,
            linear-gradient(to right, rgba(236, 72, 153, 0.3), rgba(249, 115, 22, 0.3)) no-repeat,
            linear-gradient(to bottom, rgba(59, 130, 246, 0.3), rgba(236, 72, 153, 0.3)) no-repeat
          `,
                    backgroundSize:
                      "100% 10px, 10px 100%, 100% 10px, 10px 100%",
                    backgroundPosition: "top, right, bottom, left",
                  }}
                />
                <div className="relative z-10">
                  <p className="text-white pt-4 md:pt-2 text-sm lg:text-base 2xl:text-lg mac:text-2xl font-semibold">
                    TEQBAE INNOVATIONS: Driving Business Success
                  </p>
                  <p className="py-5 2xl:py-7 mac:py-5 md:pe-10 xl:pe-10 mac:pe-16 text-xs mac:text-base text-neutral-500">
                    We deliver innovative, reliable software solutions tailored
                    to help businesses thrive with cutting-edge technology and
                    exceptional service.
                  </p>
                </div>
              </div> */
}
