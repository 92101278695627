import axios from "axios";

const axiosInstance = axios.create({
  //baseURL: process.env.REACT_APP_BASE_URL,
  baseURL:'https://mavex-erp-kv9tt.ondigitalocean.app/',
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;